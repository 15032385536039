<template>
  <main>
    <form @submit.prevent="handleSubmit" class="shadow">
      <h4>Create A New Offering</h4>
      <div class="form-outline mb-4">
        <p class="fs-5">Company Short Name</p>
        <p class="fs-6">
          Acme instead of The Acme Corporation, for an offering page address of
          roundhere.co/acme
        </p>
        <input minlength="3" type="text" id="offeringIdTextBox" required v-model="offeringId" class="form-control"
          @blur="handleBlur" />
      </div>
      <div class="form-outline mb-4">
        <p class="fs-5">Type of Offering (if Known)</p>
        <p class="fs-6">
          for example, Sale of Shares, Convertible Note, SAFE, Debt Offering, or
          unknown
        </p>
        <input type="text" required v-model="name" class="form-control" />
      </div>
      <div class="error">{{ formError }}</div>
      <div v-if="!isPending" class="row text-center">
        <div class="col">
          <button class="btn btn-primary btn-block">
            Create
          </button>
        </div>
        <div class="col">
          <router-link :to="{ name: 'Offerings' }" class="btn btn-secondary">
            Cancel
          </router-link>
        </div>
      </div>
      <div v-else>
        <button class="btn btn-secondary btn-block" disabled>
          Creating...
        </button>
      </div>
    </form>
  </main>
  <Footer />
</template>

<script>
import { computed, ref } from "vue";
import { useRouter } from "vue-router";
import useCollection from "@/composables/useCollection";
import { timestamp } from "@/firebase/config";
import getUser from "@/composables/getUser.js";
import Footer from "@/components/navigation/Footer.vue";

export default {
  components: { Footer },
  setup() {
    // Use offerings table as the unique key indicator
    const { error, addUniqueId, isUniqueId } = useCollection("offerings");
    const name = ref("");
    const offeringId = ref("");
    const { user } = getUser();

    const offeringIdClean = computed(() =>
      offeringId.value.replace(/[\W_]+/g, "").toLowerCase()
    );

    const formError = ref(null);
    const isPending = ref(false);
    const router = useRouter();

    const createOffering = async () => {
      try {
        //create record in all offering collections
        let offeringCollections = ["offeringPitches"];
        for (let i = 0; i < offeringCollections.length; i++) {
          const { error, addUniqueId } = useCollection(offeringCollections[i]);
          await addUniqueId(offeringIdClean.value, {
            highlights: "",
            videoLink: "",
            problem: "",
            solution: "",
            product: "",
            businessModel: "",
            traction: "",
            recognition: "",
            market: "",
            competition: "",
            vision: "",
            risks: "",
            issuerId: user.value.uid,
            createdAt: timestamp()
          });
          console.log(`Offering: ${offeringCollections[i]}`);
          console.log(offeringCollections[i], error.value);
          if (error.value) {
            formError.value = `${error.value} for ${offeringCollections[i]}`;
            break;
          }
        }
      } catch (err) {
        formError.value = `Error creating collections: ${err.message}`;
      }
    };

    const handleSubmit = async () => {
      isPending.value = true;
      formError.value = null;
      await addUniqueId(offeringIdClean.value, {
        name: name.value,
        issuerId: user.value.uid,
        featured: 0,
        amountRaised: 0,
        investorCount: 0,
        published: false,
        isAcceptingInvestments: false,
        requestPublish: false,
        issuerType: "company",
        regExemption: "4a6",
        tags: [],
        watchedBy: [],
        createdAt: timestamp()
      });
      if (error.value) {
        formError.value = error.value;
        isPending.value = false;
      } else {
        await createOffering();
        isPending.value = false;
        console.log("Adding Error: ", formError.value);
        if (!formError.value) {
          console.log("Offering Added");
          router.push({
            name: "OfferingDetails",
            params: { id: offeringIdClean.value }
          });
        }
      }
    };

    const handleBlur = async () => {
      if (offeringIdClean.value) {
        formError.value = null;
        let resultUnique = await isUniqueId(offeringIdClean.value);
        if (!resultUnique) {
          formError.value = "Offering ID is not unique";
        }
      }
    };

    return {
      name,
      offeringId,
      offeringIdClean,
      handleSubmit,
      handleBlur,
      formError,
      isPending
    };
  }
};
</script>

<style></style>